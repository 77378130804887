import moment from 'moment'
import React, { useEffect, useState } from 'react';
import { useDataProvider, showNotification } from 'react-admin';
import { Permissions } from '../../../permissions';
import { GET_OFF_RESOURCE_PLAIN } from '../../../../../providers/nestjs_crud';
import { Grid, Container } from '@material-ui/core';
import { makeStyles  } from '@material-ui/core/styles';  
import { Typography, Paper, Tabs, Tab, Card, CircularProgress } from '@material-ui/core'; 
import { TaskType } from '../components/TaskType';
import { getColorForJobStatus } from '../utils'
import { getColorForStatus } from '../../mission/utils'
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    rootGrid: {
      maxHeight: "100%"
    },
    rootCard: {
      padding: '10px 10px 10px 10px',
    },
    centeredTabs: {
      marginBottom: theme.spacing(2),
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.appBar,
    },
    marginBottomDefault: {
      marginBottom: theme.spacing(1),
    },
    table: {
      padding: theme.spacing(1),
      borderRadius: 0,
      border: `1px solid ${theme.palette.grey[200]}`
    },
}));

export function DeliveryManTaskForStage(props) {
    const [missions, setMissions] = useState([]);
    const [stage, setStage] = useState('pre-drive')
    const [state, setState] = useState('FETCH_DATA');
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const classes = useStyles();

    useEffect(() => {
      if(state === 'FETCH_DATA') {
        setState('FETCHING_DATA');
        dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.deliveryManMissionJob.feature}/tasks/${stage}`)
          .then((rawResponse) => {
            const response = JSON.parse(rawResponse);
            if (response.data) {
              setMissions(response.data);
            } else {
              dispatch(showNotification(`'Invalid response structure:' ${response}`));
            }
          })
          .catch(error => {
            dispatch(showNotification(`Error fetching data:' ${error}`));
          })
          .finally(() => {
            setState('DATA_FETCHED');
          })
      }
    }, [dataProvider, stage, dispatch, state]);

    if(['FETCH_DATA', 'FETCHING_DATA'].includes(state)) {
      return <CircularProgress />
    }

    if (state === 'DATA_FETCHED') {
      return (
          <Container maxWidth="xl" className={classes.root}>
              <StageTabs onChangeStage={({ stage }) => {
                setStage(stage)
                setState('FETCH_DATA')
              }} stage={stage} />
              <Grid container spacing={3} className={classes.rootGrid} >
                  {missions.map((mission) => (
                        <Mission key={mission.id} mission={mission} classes={classes} update={()=> setState('FETCH_DATA')}/>
                  ))}
              </Grid>
          </Container>
      );
    }

    return null;
}

function StageTabs({onChangeStage, stage}) {
  const classes = useStyles();
  const handleChange = (event, stage) => {
    onChangeStage({ stage });
  };

  return (
    <Paper className={classes.centeredTabs}>
      <Tabs
        value={stage}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Pre Drive" value={'pre-drive'}  />
        <Tab label="Post Drive" value={'post-drive'}  />

      </Tabs>
    </Paper>
  );
}

function Mission({ mission, classes, ...props }) {
    return (
      <Grid item xs={12} className={classes.marginBottomDefault}>
          <Card className={classes.table}>
            <MissionTable row={mission} classes={classes}></MissionTable>  
            <Grid container spacing={2} className={classes.rootCard} > 
                  <Job jobs={mission.missionJobs} classes={classes} update={props.update}  />
            </Grid>
          </Card>
      </Grid>
    );
  }

  function Job({ jobs, classes, ...props }) {
    return jobs.map((job) => (
            <Grid key={job.id} item xs={12}>
              <Card className={classes.table}>
                <Grid container spacing={1}  className={classes.marginBottomDefault}>
                        <Grid  item xs={12} className={classes.marginBottomDefault}>
                          <JobTable classes={classes} job={job} />
                        </Grid>
                        {job.tasks.map((task) => (
                          <TaskType onDone={props.update} key={task.id} task={task}></TaskType>
                        ))}  
                </Grid>
              </Card> 
            </Grid> 
          ))}

function MissionTable({row, classes}) {
  return (
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='subtitle1'>
                {row.place.name}
              </Typography >
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle1'>{formatDateTime(row.estimatedStartDate)}</Typography >
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle1' >{row.comment}</Typography >
            </Grid>
            <Grid item xs={3}>
              <MissionStatus status={row.status} />
            </Grid>
          </Grid>  
  );
}

function MissionStatus ({status}) {
  const backgroundColor = getColorForStatus(status);
  return  (
      <Typography
          style={{ backgroundColor: backgroundColor[300], textAlign: 'center'}}>
          {status}
      </Typography>
  )
}

function formatDateTime(date) {
  const currentMoment = moment(date);
  const formattedDate = currentMoment.format('HH:mm:ss');
  return formattedDate;
}

function JobTable({job, classes}) {
  return (
            <Grid container >
              <Grid item xs={3}>
                <Typography variant='body1'>
                  {job.type}
                </Typography >
              </Grid>
              <Grid item xs={3}>
                <Typography variant='body1'>{job.destination.label}</Typography >
              </Grid>
              <Grid item xs={3}>
                <Typography variant='body1' >{job.comment}</Typography >
              </Grid>
              <Grid item xs={3}>
                <JobStatus status={job.status} />
              </Grid>
          </Grid>    
  );
}

function JobStatus ({status}) {
      const backgroundColor = getColorForJobStatus(status);
      return  (
          <Typography
              style={{ backgroundColor: backgroundColor[300], textAlign: 'center'}}>
              {status}
          </Typography>
      )
}

