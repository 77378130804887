import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CachedIcon from '@material-ui/icons/Cached';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import QueueIcon from '@material-ui/icons/Queue';
import { Card, Item, CardMissingQuantity } from './';
import { GET_OFF_RESOURCE, PATCH_OFF_RESOURCE, DELETE_OFF_RESOURCE, POST_OFF_RESOURCE, GET_OFF_RESOURCE_PLAIN } from '../../../../providers/nestjs_crud';
import { Permissions } from '../../../../permissions/permissions';
import KitchenIcon from '@material-ui/icons/Kitchen';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    list: {
        width: '100%',
    },
    text: {
        margin: theme.spacing(0, 0 , 1)
    },
    itemContainer: {
        flex: 1
    },
    physicalContainer: {
        flex: 1.5,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        overflow: 'scroll',
        padding: theme.spacing(0, 0, 10)
    },
    physicalContainerShift: {
        flex: 1.5,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        overflow: 'scroll',
        padding: theme.spacing(8, 0,)
    },
    container: {
        display: 'flex',
        height: '100%',
        width: '100%' 
    },
    exitButton: {
        margin: theme.spacing(1, 0 , 0)
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(2),
    },
    fab2: {
        marginLeft: theme.spacing(2),
    },
    appBarBottom: {
        top: 'auto',
        bottom: 0,
    },
    appBarBottomToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    action: {
        width: '40%',
    }
}));

const loadingStatus = [
    'FETCHING_DATA',
    'SENDING_REQUEST_TERMINATE',
    'SENDING_REQUEST_TODO',
    'SENDING_REFRESH',
    'SUBMITTING',
];

const errorStatus = [
    'FETCHING_DATA_ERROR',
    'ERROR_SEND_SUBMIT',
    'ERROR_REFRESH',
    'ERROR_REQUEST_TERMINATE',
    'ERROR_REQUEST_TODO',
];

export default function InventoryWarehouseAccAccount(props) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const params = new URLSearchParams(location.search);
    const item = (params.get('item') === null || params.get('item') === undefined) ? 1 : (params.get('item') > 0 ? params.get('item') : 1);
    const dataProvider = useDataProvider();
    const [state, setState] = useState('FETCH_DATA');
    const [picking, setPicking] = useState(null);
    const [paginationState, setPaginationState] = useState('INITIAL');
    let numberItemPickable = 0;
    let numberItemToPick = 0;

    const updateDataPicking = (pickingData, override = {}) => {
        pickingData.requestedItemTransfers.sort((requestedItemTransferA, requestedItemTransferB) => {
            return (requestedItemTransferA.itemStorable.inventoryOrder - requestedItemTransferB.itemStorable.inventoryOrder)
        })
        setPicking(pickingData);
    }

    const updatePhysicalItemTransfer = (id, data) => {
        setPicking({
            ...picking,
            requestedItemTransfers: picking.requestedItemTransfers.map(requestedItemTransfer => {
                return {
                    ...requestedItemTransfer,
                    physicalItemTransfers: requestedItemTransfer.physicalItemTransfers.map(physicalItemTransfer => {
                        if(physicalItemTransfer.id === id) {
                            return {
                                ...physicalItemTransfer,
                                ...data,
                            }
                        } else {
                            return physicalItemTransfer;
                        }
                    })
                }
            })
        })
    }

    const deletePhysicalItemTransfer = (id) => {
        setPicking({
            ...picking,
            requestedItemTransfers: picking.requestedItemTransfers.map(requestedItemTransfer => {
                return {
                    ...requestedItemTransfer,
                    physicalItemTransfers: requestedItemTransfer.physicalItemTransfers.map(physicalItemTransfer => {
                        if(physicalItemTransfer.id === id) {
                            return null;
                        } else {
                            return physicalItemTransfer;
                        }
                    })
                    .filter(physicalItemTransfer => physicalItemTransfer)
                }
            })
        })
    }

    useEffect(() => {
        if (state === 'FETCH_DATA' && id) {
            setState('FETCHING_DATA');
            dataProvider(GET_OFF_RESOURCE, `transfer/deep/${id}`)
                .then(({ data }) => {
                    if(
                        data.success === true &&
                        data.data.transfer.transferStateMachine &&
                        data.data.transfer.transferStateMachine.status === 'wip' &&
                        data.data.transfer.type !== 'SupplierReception' &&
                        Array.isArray(data.data.requestedItemTransfers) && data.data.requestedItemTransfers.length > 0
                    ) {
                        setState('DATA_FETCHED');
                        updateDataPicking(data.data);
                    } else {
                        setState('FETCHING_DATA_ERROR');
                    }
                })
                .catch(error => {
                    setState('FETCHING_DATA_ERROR');
                })
        }
        return () => {}
    }, [dataProvider, state, id]);

    useEffect(() => {
        if (state === 'DATA_FETCHED' && id && picking && (params.get('item') === null || params.get('item') === undefined)) {
            let itemIndex = picking.requestedItemTransfers.findIndex(requestedItemTransfer => {
                let picked = 0;

                if(requestedItemTransfer.physicalItemTransfers && Array.isArray(requestedItemTransfer.physicalItemTransfers)) {
                    requestedItemTransfer.physicalItemTransfers.forEach(physicalItemTransfer => {
                    if(physicalItemTransfer.status === "Reserved" && physicalItemTransfer.done) {
                        picked = Number.parseFloat((picked + physicalItemTransfer.quantityReference).toFixed(2));
                    }
                    });
                }
                return picked !== requestedItemTransfer.quantityReference;
            });

            if(itemIndex === null || itemIndex === undefined || itemIndex === -1) {
                itemIndex = 0;
            }

            history.replace(`/picking/${id}?item=${itemIndex + 1}`)
        }
    }, [history, id, params, picking, state]);

    useEffect(() => {
        if (picking && id && (paginationState === 'NEXT_TODO' || paginationState === 'PREVIUS_TODO')) {
            let itemIndex = picking.requestedItemTransfers.findIndex((requestedItemTransfer, index) => {
                return requestedItemTransfer.physicalItemTransfers.filter(physicalItemTransfer => {
                    return !physicalItemTransfer.done && physicalItemTransfer.status === 'Reserved';
                }).length > 0 && ((paginationState === 'PREVIUS_TODO' && index < item - 1) || (paginationState === 'NEXT_TODO' && index > item - 1)) 
            });

            if(itemIndex !== null && itemIndex !== undefined && itemIndex > 0) {
                setPaginationState('INITIAL')
                history.replace(`/picking/${id}?item=${itemIndex + 1}`)
            } else {
                setPaginationState('INITIAL');
                history.replace(`/picking/${id}?item=${paginationState === 'NEXT_TODO' ? picking.requestedItemTransfers.length + 1 : 1}`)
            }
        }
    }, [history, id, paginationState, picking, item]);

    useEffect(() => {
        if (id && state === 'REQUEST_TERMINATE') { 
            setState('SENDING_REQUEST_TERMINATE');
            dataProvider(POST_OFF_RESOURCE, `transfer/${id}/move-to/picked`, {
                data: {}
            })
            .then(({data}) => {
                history.goBack();
            })
            .catch((e) => {
                setState('ERROR_REQUEST_TERMINATE');
            });
        }
    }, [history, state, dataProvider, id]);

    useEffect(() => {
        if (id && state === 'REQUEST_TODO') {
            setState('SENDING_REQUEST_TERMINATE');
            dataProvider(POST_OFF_RESOURCE, `transfer/${id}/move-to/todo`, {
                data: {}
            })
            .then(({data}) => {
                history.goBack();
            })
            .catch((e) => {
                setState('ERROR_REQUEST_TODO');
            });
        }
    }, [history, state, dataProvider, id]);

    const handleClose = () => {
        history.goBack()
        return () => {}
    };
    if(state === 'DATA_FETCHED' && picking && picking.requestedItemTransfers && Array.isArray(picking.requestedItemTransfers) && picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1]) {
        const requestedItemTransfer = picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1];
        numberItemPickable =
            requestedItemTransfer.physicalItemTransfers 
                .filter(item => item.status === "Reserved")
                .reduce((total, item) => total + item.quantityReference, 0);
        numberItemToPick = requestedItemTransfer.quantityReference;
    }

    return (
        <Dialog fullScreen open={true} onClose={handleClose}>
            <Container className={classes.container} maxWidth={false} disableGutters>
                <Container className={classes.itemContainer} disableGutters>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography className={classes.title}>
                                {state !== 'DATA_FETCHED' && 'Picking'}
                                {state === 'DATA_FETCHED' && picking && picking.transfer && picking.transfer.fromWarehouseLocation && picking.transfer.toWarehouseLocation && <><small>a</small> <strong>{picking.transfer.toWarehouseLocation.name}</strong> <small>da</small> <strong>{picking.transfer.fromWarehouseLocation.name}</strong><br></br></>}
                                {state === 'DATA_FETCHED' && picking && picking.transfer && picking.transfer.deliveryMainUser && <><small>delivery</small> <strong>{picking.transfer.deliveryMainUser.label}</strong></>}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {state === 'DATA_FETCHED' && picking && picking.requestedItemTransfers && Array.isArray(picking.requestedItemTransfers) && <Item {...{
                        returnTodo: () => setPaginationState('PREVIUS_TODO'),
                        goTodo: () => setPaginationState('NEXT_TODO'),
                        itemLenght: picking.requestedItemTransfers.length,
                        terminate: () => setState('REQUEST_TERMINATE'),
                        todo: () => setState('REQUEST_TODO'),
                        item: {
                            id: picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].itemId,
                            title: picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].item.name,
                            uom: picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].item.unitOfMeasurements.find(unitOfMeasurement => unitOfMeasurement.default) ? picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].item.unitOfMeasurements.find(unitOfMeasurement => unitOfMeasurement.default).name : false || 'PZ',
                            image: picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].item.photoURL || 'https://via.placeholder.com/1000x1500?text=Immagine+non-trovata'
                        },
                        requestedItemTransfer: picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1],
                    }} />}
                </Container>
                <Container className={classes.physicalContainer} disableGutters>
                    {state === 'DATA_FETCHED' && picking && picking.requestedItemTransfers && Array.isArray(picking.requestedItemTransfers) && Array.isArray(picking.requestedItemTransfers) &&
                    picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1] && 
                    Array.isArray(picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].physicalItemTransfers) &&
                    <List className={classes.list}> 
                    {numberItemPickable < numberItemToPick && <ListItem><CardMissingQuantity  label={`Merce mancante: ${numberItemToPick - numberItemPickable}`}/></ListItem>} 
                    {  
                        picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].physicalItemTransfers.map((physicalItemTransfer, physicalItemTransferKey) => {
                            if(physicalItemTransfer.status === 'Reserved') {
                                return (
                                    <ListItem key={physicalItemTransfer.id}>
                                        <Card
                                            physicalItemTransfer={physicalItemTransfer}
                                            cancel={physicalItemTransferId => {
                                                setState('FETCHING_DATA');
                                                dataProvider(DELETE_OFF_RESOURCE, `${Permissions.transfer.feature}/${id}/${Permissions.transfer.subFeatures.physicalItemTransfer.name}/${physicalItemTransferId}`)
                                                .then(({ data }) => {
                                                    if(data.success === true) {
                                                        deletePhysicalItemTransfer(physicalItemTransferId, { done: true })
                                                        setState('DATA_FETCHED');
                                                    } else {
                                                        setState('FETCHING_DATA_ERROR');
                                                    }
                                                })
                                                .catch(error => {
                                                    setState('FETCHING_DATA_ERROR');
                                                })
                                            }}
                                            confirm={physicalItemTransferId => {
                                                setState('FETCHING_DATA');
                                                dataProvider(PATCH_OFF_RESOURCE, `${Permissions.transfer.feature}/${id}/${Permissions.transfer.subFeatures.physicalItemTransfer.name}/${physicalItemTransferId}`, {
                                                    data: { done: true }
                                                })
                                                .then(({ data }) => {
                                                    if(data.success === true) {
                                                        setState('DATA_FETCHED');
                                                        updatePhysicalItemTransfer(physicalItemTransferId, { done: true })
                                                        const is_last = picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].physicalItemTransfers.filter(physicalItemTransfer => {
                                                            return !physicalItemTransfer.done && physicalItemTransfer.status === 'Reserved';
                                                        }).length === 1;
                                                        if(is_last && Number.parseInt(item) < picking.requestedItemTransfers.length) {
                                                            history.replace(`/picking/${id}?item=${Number.parseInt(item) + 1}`)
                                                        }
                                                    } else {
                                                        setState('FETCHING_DATA_ERROR');
                                                    }
                                                })
                                                .catch(error => {
                                                    setState('FETCHING_DATA_ERROR');
                                                })
                                            }}
                                            toDo={physicalItemTransferId => {
                                                setState('FETCHING_DATA');
                                                dataProvider(PATCH_OFF_RESOURCE, `${Permissions.transfer.feature}/${id}/${Permissions.transfer.subFeatures.physicalItemTransfer.name}/${physicalItemTransferId}`, {
                                                    data: { done: false }
                                                })
                                                .then(({ data }) => {
                                                    if(data.success === true) {
                                                        updatePhysicalItemTransfer(physicalItemTransferId, { done: false })
                                                        setState('DATA_FETCHED');
                                                    } else {
                                                        setState('FETCHING_DATA_ERROR');
                                                    }
                                                })
                                                .catch(error => {
                                                    setState('FETCHING_DATA_ERROR');
                                                })
                                            }}
                                        />
                                    </ListItem>
                                );
                            }
                            return null;
                        })
                    }
                    </List>}
                </Container>
                <Backdrop className={classes.backdrop} open={errorStatus.includes(state)} onClick={() => { }}>
                    <Typography className={classes.text} variant="h4">
                        {('ERROR_REFRESH' === state) && 'Errore durante invio dati'}
                        {state === 'ERROR_SEND_SUBMIT' && 'Errore invio dati, aggiorna le quantità'}
                        {state === 'FETCHING_DATA_ERROR' && 'Errore durante il caricamento'}
                        {(state === 'ERROR_REQUEST_TERMINATE' || state === 'ERROR_REQUEST_TODO') && 'Completare o Cancellare i trasferimenti prima di uscire'}
                    </Typography>
                    {state === 'FETCHING_DATA_ERROR' && <Button
                        size="large"
                        onClick={() => {
                            setState('FETCH_DATA');
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}>
                        RICARICA
                    </Button>}
                    {(state === 'ERROR_REQUEST_TERMINATE' || state === 'ERROR_REQUEST_TODO') && <Button
                        size="large"
                        onClick={() => {
                            setState('DATA_FETCHED');
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<QueueIcon />}>
                        CONTINUA PICKING
                    </Button>}
                    {(state === 'FETCHING_DATA_ERROR' || state === 'ERROR_REQUEST_TERMINATE' || state === 'ERROR_REQUEST_TODO') && <Button
                        className={classes.exitButton}
                        size="small"
                        onClick={handleClose}
                        variant="contained"
                        color="default">
                        ESCI
                    </Button>}
                    {state === 'ERROR_SEND_SUBMIT' && <Button
                        size="large"
                        onClick={() => {
                            setState('DATA_FETCHED');
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}>
                        CHIUDI
                    </Button>}
                </Backdrop>
                <Backdrop className={classes.backdrop} open={loadingStatus.includes(state)} onClick={null}>
                    <Typography className={classes.text} variant="h4">
                        {(state === 'SENDING_REFRESH' || state === 'SUBMITTING') && 'Invio dati in corso'}
                        {state === 'FETCHING_DATA' && 'Caricamento dati in corso'}
                    </Typography>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className={classes.fab}>
                {state === 'DATA_FETCHED' && picking && picking.requestedItemTransfers && Array.isArray(picking.requestedItemTransfers) && Array.isArray(picking.requestedItemTransfers) &&
                    picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1] && 
                    Array.isArray(picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].physicalItemTransfers) && <Fab variant="extended" onClick={() => history.push(`${location.pathname}/request-shift?requestedItemTransferId=${picking.requestedItemTransfers[item > picking.requestedItemTransfers.length ? picking.requestedItemTransfers.length - 1 : item - 1].id}`)} color="primary" aria-label="add">
                    <AddIcon />
                    AGGIUNGI
                </Fab>}
                    {state === 'DATA_FETCHED' && picking && picking.transfer && picking.transfer.fromWarehouseLocation && picking.transfer.fromWarehouseLocation.locker && picking.transfer.fromWarehouseLocation.locker.lockId && <Fab onClick={() => {
                    dataProvider(GET_OFF_RESOURCE_PLAIN, `lock/${picking.transfer.fromWarehouseLocation.locker.lockId}/open`)
                        .then(() => {})
                        .catch(() => {});
                    }} className={classes.fab2} variant="extended" color="secondary" aria-label="add">
                        <KitchenIcon />
                        Apri
                    </Fab>}
                </div>
            </Container>
        </Dialog>
    );
}