import React, { useEffect, useState } from "react";
import {
	DateInput,
	usePermissions,
	SimpleForm,
	required,
	useDataProvider,
	showNotification,
} from "react-admin";
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Typography,
	Box,
} from "@material-ui/core";
import { Permissions } from "../../../../../permissions/permissions";
import { Permissions as OpsPermissions } from "../../../permissions";
import RestoreIcon from "@material-ui/icons/Restore";
import { POST_OFF_RESOURCE } from "../../../../../providers/nestjs_crud";
import { useDispatch } from "react-redux";

export function ExecuteForceButton(props) {
	const [openDialog, setOpenDialog] = useState(false);
	const [formData, setFormData] = useState({
		recurringMissionId: props.record.id,
		executeDate: null,
		hubId: props.record.hubId,
	});
	const [error, setError] = useState("");
	const [status, setStatus] = useState("INITIAL");
	const { permissions } = usePermissions();
	const dataProvider = useDataProvider();
	const dispatch = useDispatch();

	const hubId = props.record.hubId;

	const handleDialogStatus = (e) => {
		e.stopPropagation();
		setError("");
		setFormData({
			recurringMissionId: props.record.id,
			executeDate: null,
			hubId: props.record.hubId,
		});
		setOpenDialog((curr) => !curr);
	};

	const handleFormSubmit = (e) => {
		setError("");
		if (
			!formData.executeDate ||
			!formData.hubId ||
			!formData.recurringMissionId
		) {
			setError("Compilare tutti i campi");
			return;
		}

		const executeDate = new Date(formData.executeDate);
		executeDate.setHours(0, 0, 0, 0);
		const minDate = new Date(props.record.startDate);
		minDate.setHours(0, 0, 0, 0);

		let maxDate: Date;

		if(props.record.endDate){
			maxDate = new Date(props.record.endDate);
			maxDate.setHours(0, 0, 0, 0);
		}


		switch (props.record.type) {
			case "weekly":
				if (executeDate.getDay() !== props.record.weekday) {
					setError("Il giorno della settimana non è valido");
					return;
				}
				break;
			default:
				break;
		}

		if (executeDate < minDate || (maxDate && executeDate > maxDate)) {
			setError(
				"La data di esecuzione deve essere compresa tra la data di inizio e la data di fine"
			);
			return;
		}
		setStatus("SEND");
	};

	useEffect(() => {
		if (status === "SEND") {
			setStatus("SENDING");

			dataProvider(
				POST_OFF_RESOURCE,
				`${Permissions.recurringMissionExecution.feature}/${Permissions.recurringMissionExecution.actions.ExecuteCreateAgain}`,
				{
					data: {
						recurringMissionId: formData.recurringMissionId,
						date: formData.executeDate,
					},
				}
			)
				.then((message) => {
					setStatus("SENT");
					setOpenDialog(false);
				})
				.catch((e) => {
					dispatch(
						showNotification(
							`Errore durante la generazione: ${e}`,
							"error"
						)
					);
					setStatus("SENDING_ERROR");
				});
		}
	}, [dataProvider, dispatch, formData, status]);

	if (!props.record) {
		return null;
	}

	if (
		!permissions ||
		!permissions.includes(
			`${OpsPermissions.recurringMissionExecution.feature}-${OpsPermissions.recurringMissionExecution.actions.ExecuteCreateAgain}`
		) ||
		props.record.stateMachine?.status !== "READY"
	) {
		return null;
	}
	return (
		<Box {...props}>
			<Button
				startIcon={<RestoreIcon />}
				color="primary"
				variant="text"
				size="small"
				onClick={handleDialogStatus}
				disabled={sessionStorage.getItem("hubSelected") !== hubId}
			>
				Crea nuovamente
			</Button>
			<Dialog open={openDialog} onClose={handleDialogStatus} fullWidth>
				<DialogTitle>Crea nuovamente</DialogTitle>
				<DialogContent>
					<SimpleForm toolbar={false}>
						<DateInput
							fullWidth
							locales="it-IT"
							onChange={(e) =>
								setFormData({
									...formData,
									executeDate: e.target.value,
								})
							}
							validate={[
								required("campo obbligatorio"),
								(value) => {
									if (!value || !isNaN(new Date(value)))
										return;

									return "Data non valida";
								},
							]}
							label="Data"
							source="date"
						/>
					</SimpleForm>
				</DialogContent>
				<DialogActions>
					<Typography variant="body2" color="error">
						{error}
					</Typography>
					<Button
						color="primary"
						onClick={handleFormSubmit}
						disabled={status === "SENDING"}
					>
						Crea
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
