import React from "react";
import {
	Datagrid,
	List,
	TextField,
	DateField,
	ReferenceField,
	ReferenceInput,
	AutocompleteInput,
	DateInput,
	AutocompleteArrayInput,
	ShowButton,
} from "react-admin";
import { makeStyles, Typography } from "@material-ui/core";
import { Permissions } from "../../../../../permissions/permissions";
import Filter from "../../../../inheritance/BaseFilter";
import { ExecuteForceButton } from "../Components/ExecuteForceButton";
import {
	repeatTypesChoices,
	weekdaysChoices,
} from "../Components/RepeatOptionInput";

const useStyles = makeStyles((theme) => ({
	expired: {
		color: "red",
	},
}));

const status = [
	{ id: "DRAFT", name: "DRAFT" },
	{ id: "READY", name: "READY" },
	{ id: "CANCELLED", name: "CANCELLED" },
	{ id: "ERROR", name: "ERROR" },
];

function RecurringMissionFilter(props) {
	return (
		<Filter {...props}>
			<ReferenceInput
				filterToQuery={(searchText) => {
					if (!searchText) return {};

					return {
						"mainUser.label": searchText,
					};
				}}
				label="Corriere suggerito"
				source="suggestedDeliveryManId||eq"
				reference={Permissions.deliveryMan.feature}
			>
				<AutocompleteInput
					optionText={(record) => {
						if (!record || !record.id) {
							return "cancel";
						}
						return `${
							record
								? `${record.mainUser.id} - ${record.mainUser.label}`
								: ""
						}`;
					}}
					optionValue="id"
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				filterToQuery={(searchText) => {
					if (!searchText) return {};

					return {
						name: searchText,
					};
				}}
				label="Luoghi"
				source="placeId||eq"
				reference={Permissions.place.feature}
				emptyText="cancel"
			>
				<AutocompleteInput optionText="name" optionValue="id" />
			</ReferenceInput>
			<AutocompleteArrayInput
				alwaysOn
				label="STATO"
				source="stateMachine.status||in"
				choices={status}
				translateChoice={false}
				allowEmpty={false}
			/>
			<AutocompleteArrayInput
				label="NON IN STATO"
				source="stateMachine.status||notin"
				choices={status}
				translateChoice={false}
				allowEmpty={false}
			/>
			<DateInput source={`startDate||gte`} label={`Data di inizio Da`} />
			<DateInput source={`startDate||lte`} label={`Data di inizio A`} />
			<DateInput source={`endDate||gte`} label={`Data di fine Da`} />
			<DateInput source={`endDate||lte`} label={`Data di fine A`} />
		</Filter>
	);
}

export function RecurringMissionList(props) {
	return (
		<List
			{...props}
			bulkActionButtons={<></>}
			filters={<RecurringMissionFilter />}
			sort={{ field: "created_at", order: "DESC" }}
		>
			<Datagrid rowClick={false}>
				<ReferenceField
					link="show"
					label="Luoghi"
					source="placeId"
					reference={Permissions.place.feature}
				>
					<TextField source="name" />
				</ReferenceField>
				<DateField
					locales="it-IT"
					label="Data di inizio"
					source="startDate"
					options={{ timeZone: "UTC" }}
				/>
				<CheckExpiredDateField label="Data di fine" source="endDate" />
				<RepeatInfoField label="Ricorrenza" />
				<ReferenceField
					link="show"
					label="Corriere Suggerito"
					source="suggestedDeliveryManId"
					reference={Permissions.deliveryMan.feature}
				>
					<TextField source="mainUser.label" />
				</ReferenceField>
				<TextField label="Commento" source="comment" />
				<TextField label="Stato" source="stateMachine.status" />
				<ExecuteForceButton />
				<ShowButton />
			</Datagrid>
		</List>
	);
}

function RepeatInfoField(props) {
	if (!props.record || !props.record.type) {
		return null;
	}

	if (props.record.weekday === null || props.record.weekday === undefined) {
		return null;
	}

	const weekday = weekdaysChoices.find(
		(weekday) => weekday.id === props.record.weekday
	);

	const type = repeatTypesChoices.find(
		(type) => type.id === props.record.type
	);

	switch (props.record.type) {
		case "weekly":
			return (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Typography
						variant="caption"
						style={{ color: "#0000008a" }}
					>
						{type.name}:
					</Typography>
					<Typography variant="body2">
						{weekday?.name} &bull; {props.record.maxExecutionTime}
					</Typography>
				</div>
			);
		default:
			return <div></div>;
	}
}

function CheckExpiredDateField(props) {
	const classes = useStyles();
	const { record } = props;
	if (!record || !record.endDate) {
		return null;
	}

	const date = new Date(record.endDate.split("T")[0]);

	return (
		<Typography
			variant="p"
			className={date < new Date() ? classes.expired : ""}
		>
			{date.toLocaleDateString()}
		</Typography>
	);
}
