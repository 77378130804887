import React from "react";
import { useState } from "react";
import { required, SelectField, SelectInput, } from "react-admin";
import { Box, Typography } from "@material-ui/core";

export const repeatTypesChoices = [{ id: "weekly", name: "Settimanale" }];
export const weekdaysChoices = [
	{ id: 1, name: "Lunedì" },
	{ id: 2, name: "Martedí" },
	{ id: 3, name: "Mercoledí" },
	{ id: 4, name: "Giovedì" },
	{ id: 5, name: "Venerdì" },
	{ id: 6, name: "Sabato" },
	{ id: 0, name: "Domenica" },
];

function CustomField(props){
	return (
		<Box display="flex" flexDirection="column" style={{gap:'5px', marginBottom:"7px"}}>
			<Typography variant="caption" style={{color:"#0000008a"}}>
				{props.title}
			</Typography>
			<Typography variant="p">
				{props.record.name}
			</Typography>
		</Box>
	)
}

export function RepeatOptionInput(props) {
	const defaultValue = "weekly";
	const [type, setType] = useState(defaultValue);

	if(props.show){
		return (
			<Box display="flex" flexDirection="column">
				<SelectField
					{...props}
					optionText={<CustomField title="Ricorrenza"/>}
					source="type"
					choices={repeatTypesChoices}
				/>
				{type === "weekly" && (
					<SelectField
						{...props}
						optionText={<CustomField title="Giorno"/>}
						source="weekday"
						choices={weekdaysChoices}
					/>
				)}
			</Box>
		);
	}
	else{
		return (
			<Box display="flex" flexDirection="column">
				<SelectInput
					{...props}
					source="type"
					defaultValue={defaultValue}
					choices={repeatTypesChoices}
					validate={required("Campo obbligatorio")}
					onChange={(e) => setType(e.target.value)}
				/>
				{type === "weekly" && (
					<SelectInput
						{...props}
						source="weekday"
						defaultValue="1"
						choices={weekdaysChoices}
						validate={required("Campo obbligatorio")}
					/>
				)}
			</Box>
		);
	}
}
